<template>
  <div class="picture-display bg-dark">
    <el-image
      class="display-bg"
      @contextmenu="handleMouse"
      :src="banner_url"
      :fit="'cover'"
    ></el-image>
    <!-- <img src="@/assets/images/display-bg-picture.jpg" class="display-bg" /> -->
    <div class="container">
      <search @change="searchPicture" @select="selectType"></search>
      <div class="keyword">
        <span
          v-for="item in searchtaginfo"
          :key="item.id"
          @click="handleSearch(item.tagname)"
          >{{ item.tagname }}</span
        >
      </div>
      <div class="classify">
        <el-row>
          <el-col
            class="grid-content1"
            :span="8"
            v-for="item in categoryinfoList"
            :key="item.id"
            ><div
              @click="handleSearchPicture(item.name, true)"
              :class="{ hovered: hover && currentId == item.id }"
              @mouseenter="mouseenter(item.id)"
              @mouseleave="mouseleave(item.id)"
            >
              <div class="grid-content-box"></div>
              <div class="grid-label">
                <h3>{{ item.name }}</h3>
                <div class="line"></div>
                <!-- <img :src="item.showimage" /> -->
              </div>

              <el-image
                @contextmenu="handleMouse"
                :src="item.showimage"
                :fit="'cover'"
                class="grid-img1"
              ></el-image></div
          ></el-col>
        </el-row>
      </div>
      <!-- <div class="recommend">
        <p class="title title-mark">
          每日精彩推荐
          <span class="more" @click="morePicture">查看更多>></span>
        </p>
        <el-row>
          <el-col
            class="grid-content"
            :span="8"
            v-for="item in recommend"
            :key="item.id"
            ><div>
              <el-image
                @contextmenu="handleMouse"
                :src="item.url"
                :fit="'cover'"
                class="grid-img"
                @click="handleDetail(item)"
              ></el-image>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ item.resourceinfo.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(item.resourceinfo.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item)"
                    />
                    <img
                      v-if="item.resourceinfo.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(item.resourceinfo.id)"
                      class="m-l"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(item.resourceinfo.id)"
                      class="m-l"
                    />

                    <span>{{ item.resourceinfo.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
      </div> -->
      <div class="recommend" v-for="item in blockList" :key="item.id">
        <p class="title title-mark">
          <span class="left"
            ><i></i><span class="name">{{ item.name }}</span></span
          >
          <span v-if="item.type !== 2" class="more" @click="morePicture()"
            >查看更多>></span
          >
        </p>
        <div v-if="item.type == 1" class="block-1">
          <div class="outside-box grid-content-first">
            <div class="grid-content">
              <el-image
                :src="item.blockinfo[0].url"
                @contextmenu="handleMouse"
                @click="handleDetail(item.blockinfo[0])"
                :fit="'cover'"
                class="grid-img"
              ></el-image>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">
                    {{ item.blockinfo[0].resourceinfo.title }}
                  </p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="
                        handleCollection(item.blockinfo[0].resourceinfo.id)
                      "
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item.blockinfo[0])"
                    />
                    <img
                      v-if="item.blockinfo[0].resourceinfo.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="
                        handleLikeNew(
                          item.blockinfo[0].resourceinfo.id,
                          item.blockinfo
                        )
                      "
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="
                        handleLikeNew(
                          item.blockinfo[0].resourceinfo.id,
                          item.blockinfo
                        )
                      "
                    />
                    <span>{{ item.blockinfo[0].resourceinfo.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="outside-box box-right">
            <template v-for="(m, index) in item.blockinfo">
              <div
                class="grid-content"
                v-if="index > 0 && index < 5"
                :key="index"
              >
                <el-image
                  :src="m.url"
                  @click="handleDetail(m)"
                  @contextmenu="handleMouse"
                  :fit="'cover'"
                  class="grid-img"
                ></el-image>
                <div class="grid-text">
                  <div class="grid-oper">
                    <p class="name">
                      {{ m.resourceinfo.title }}
                    </p>
                    <div>
                      <img
                        src="@/assets/images/collection-1.png"
                        @click="handleCollection(m.resourceinfo.id)"
                      />
                      <img
                        src="@/assets/images/forward-1.png"
                        @click="handleShare(m)"
                      />
                      <img
                        v-if="m.resourceinfo.is_zan"
                        src="@/assets/images/like-fill.png"
                        @click="
                          handleLikeNew(m.resourceinfo.id, item.blockinfo)
                        "
                      />
                      <img
                        v-else
                        src="@/assets/images/like-1.png"
                        @click="
                          handleLikeNew(m.resourceinfo.id, item.blockinfo)
                        "
                      />
                      <span>{{ m.resourceinfo.fabulous }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <el-row v-if="item.type == 3" :gutter="14">
          <el-col
            :span="6"
            v-for="child in item.blockinfo"
            :key="child.id"
            class="grid-col"
            ><div class="grid-content">
              <el-image
                style="height: 176px;min-width: 100%; cursor: pointer;"
                @contextmenu="handleMouse"
                @click="handleDetail(child)"
                :src="child.url"
                :fit="'cover'"
                class="grid-img"
              ></el-image>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ child.resourceinfo.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(child.resourceinfo.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(child)"
                    />
                    <img
                      v-if="child.resourceinfo.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="
                        handleLikeNew(child.resourceinfo.id, item.blockinfo)
                      "
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="
                        handleLikeNew(child.resourceinfo.id, item.blockinfo)
                      "
                    />
                    <span>{{ child.resourceinfo.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
        <el-row v-if="item.type == 5">
          <el-col
            class="grid-content1"
            :span="8"
            v-for="item in recommend"
            :key="item.id"
            ><div>
              <el-image
                @contextmenu="handleMouse"
                :src="item.url"
                :fit="'cover'"
                class="grid-img1"
                @click="handleDetail(item)"
              ></el-image>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ item.resourceinfo.title }}</p>
                  <div class="icon-box">
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(item.resourceinfo.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item)"
                    />
                    <img
                      v-if="item.resourceinfo.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(item.resourceinfo.id)"
                      class="m-l"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(item.resourceinfo.id)"
                      class="m-l"
                    />

                    <span>{{ item.resourceinfo.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!--分享组件-->
    <poster ref="Poster"></poster>
  </div>
</template>

<script>
import search from "../../../components/search/index";
import poster from "@/components/sharePoster/index.vue";
import toLogin from "@/mixins/toLogin";
export default {
  name: "pictureDisplay",
  components: { search, poster },
  mixins: [toLogin],
  data() {
    return {
      banner_url: require("@/assets/images/display-bg-picture.jpg"),
      hover: false,
      user_id: "",
      blockList: [],
      searchtaginfo: [],
      categoryinfoList: [],
      recommend: [],
      searchType: "picture"
    };
  },
  async mounted() {
    console.log("mounted......");
    const user_id = localStorage.getItem("user_id");
    this.user_id = user_id;
    const { data } = await this.$api.getblockList({
      page_id: 1,
      user_id: user_id
    });
    if (data.code === 1) {
      this.blockList = data.data.blockinfos;
      this.recommend = this?.blockList
        .filter(i => i.type == 5)[0]
        ?.blockinfo.slice(0, 9);
      this.categoryinfoList = data.data.categoryinfo;
      //banner_url如需配成从后台获取打开此处注释
      // this.banner_url = data.data.bannerlist[0]?.banner_url
      //   ? data.data.bannerlist[0]?.banner_url
      //   : require("@/assets/images/display-bg-picture.jpg");
      this.searchtaginfo = data.data.searchtaginfo;
      console.log(123456789, this.recommend);
    }
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    mouseenter(id) {
      this.hover = true;
      this.currentId = id;
    },
    mouseleave() {
      this.currentId = "";
      this.hover = false;
    },
    handleSearch(tagname) {
      if (this.searchType == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type: this.searchType, text: tagname }
        });
      }
      if (this.searchType == "video") {
        this.$router.push({
          name: "videoSearch",
          query: { type: this.searchType, text: tagname }
        });
      }
    },
    handleSearchPicture(name) {
      localStorage.setItem("isHideText", true);
      this.$router.push({
        name: "pictureSearch",
        query: { type: "picture", text: name }
      });
    },
    selectType(type) {
      this.searchType = type;
    },
    searchPicture(val) {
      const { text, type } = val;

      if (type == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type, text }
        });
      }
      if (type == "video") {
        this.$router.push({
          name: "videoSearch",
          query: { type, text }
        });
      }
    },
    morePicture() {
      this.$router.push({ name: "pictureSearch" });
    },
    handleDetail(item) {
      this.$router.push({
        name: "picturePreview",
        query: { id: item.resource_id }
      });
    },
    async handleCollection(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(123, id);
      const { data } = await this.$api.postShouCang({
        resource_id: id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
      }
    },
    async handleShare(info) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(info);
      const qrContent =
        window.location.origin +
        "#/picture/preview?id=" +
        info.resource_id +
        "";
      const posterInfo = {
        nickname: info.resourceinfo.nickname,
        headimage: info.resourceinfo.headimage,
        user_description: info.resourceinfo.user_description,
        url: info.url,
        title: info.resourceinfo.title,
        description: info.resourceinfo.description || "暂无简介",
        resource_id: info.resource_id,
        content: qrContent
      };
      this.$refs["Poster"].show(posterInfo);
      const { data } = await this.$api.postZhuanFa({
        resource_id: info.id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        console.log(data.msg);
      }
    },
    async handleLike(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(789, id);
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id
      });
      const resource = this.recommend.filter(i => i.resource_id === id)[0];
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
        resource.resourceinfo.fabulous = resource.resourceinfo.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
        resource.resourceinfo.fabulous = resource.resourceinfo.fabulous - 1;
      }
      resource.resourceinfo.is_zan = !resource.resourceinfo.is_zan;
    },
    //新增模块的点赞，变了一百次的需求。。。
    async handleLikeNew(id, list) {
      console.log(789, id);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id
      });
      const resource = list.filter(i => i.resource_id === id)[0];
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
        resource.resourceinfo.fabulous = resource.resourceinfo.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
        resource.resourceinfo.fabulous = resource.resourceinfo.fabulous - 1;
      }
      resource.resourceinfo.is_zan = !resource.resourceinfo.is_zan;
    }
  }
};
</script>

<style scoped lang="scss">
.picture-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  .display-bg {
    width: 100%;
    height: 534px;
  }
  .container {
    padding: 0 0px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 78px 0 120px;
  }
  .keyword {
    margin: 19px 0 99px 0;
    span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      margin: 0 15px;
      cursor: pointer;
      letter-spacing: 5px;
    }
  }
  .classify {
    width: 100%;
    .grid-content1 {
      position: relative;
      cursor: pointer;
      .grid-content-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: #000000;
        opacity: 0.6;
      }
      &:nth-child(1) {
        .grid-img1 {
          padding-left: 0 !important;
        }
      }
      &:nth-child(3) {
        .grid-img1 {
          padding-right: 0 !important;
        }
      }
      .grid-label {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        text-align: center;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 20px;
        }
        .line {
          width: 36px;
          height: 6px;
          background: #ffffff;
          border-radius: 3px;
          margin-top: 10px;
          position: relative;
          margin: 24px auto 0;
          transform: translate(-10px, 0);
          &:after {
            content: " ";
            position: absolute;
            right: -8px;
            top: 0;
            width: 6px;
            height: 6px;
            background: #ffffff;
            border-radius: 6px;
          }
        }
        img {
          width: 36px;
          height: 6px;
        }
      }
      .grid-img1 {
        height: 278px;
        width: 415.5px;
        padding: 0 3px;
      }
      &:nth-child(0) {
        .grid-img1 {
          padding-left: 0;
        }
      }
      &:nth-child(2) {
        .grid-img1 {
          padding-right: 0;
        }
      }
      .hovered {
        .line {
          width: 36px;
          height: 6px;
          background: #e60021;
          border-radius: 3px;
          margin-top: 10px;
          position: relative;
          margin: 24px auto 0;
          transform: translate(-10px, 0);
          &:after {
            content: " ";
            position: absolute;
            right: -8px;
            top: 0;
            width: 6px;
            height: 6px;
            background: #e60021;
            border-radius: 6px;
          }
        }
      }
    }
  }
  .recommend {
    margin-top: 134px;
    .block-1 {
      display: flex;
      .outside-box {
        width: 50%;
      }
      .grid-content-first {
        .grid-content {
          padding: 0;
          cursor: pointer;
        }
      }
      .box-right {
        display: flex;
        flex-wrap: wrap;
        padding-left: 7px;
        .grid-content {
          cursor: pointer;
          &:nth-child(2),
          &:nth-child(4) {
            margin-left: 14px;
          }
        }
      }
    }
    .title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 36px;
      margin-left: 10px;
      .more {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 100;
        color: #ffffff;
        position: relative;
        top: 32px;
        right: 10px;
        cursor: pointer;
      }
    }
    .grid-content1 {
      position: relative;
      margin-bottom: 10.5px;
      cursor: pointer;
      &:nth-child(3n + 1) {
        .grid-img {
          padding-left: 0 !important;
        }
      }
      &:nth-child(3n) {
        .grid-img {
          padding-right: 0 !important;
        }
      }
      .grid-img {
        height: 278px;
        width: 415.5px;
        padding: 0 3px;
        cursor: pointer;
      }
      .grid-text {
        display: none;
      }
      .grid-oper {
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 47px;
        align-items: flex-end;
        padding: 0 12px 10px;
        font-size: 14px;
        .name {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        .icon-box {
          padding-right: 12px;
        }
        img {
          height: 17px;
          cursor: pointer;
          margin-left: 8px;
        }
        .m-l {
          margin-left: 8px;
          height: 19px;
        }
        span {
          margin-left: 5px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          position: relative;
          top: -3px;
        }
      }
      &:hover {
        .grid-text {
          display: block;
        }
      }
    }
    .grid-content {
      position: relative;
      margin-bottom: 14px;
      height: 176px;
      .sample-player-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 176px;
        overflow: hidden;
        z-index: 0;
        cursor: pointer;
        .video-player {
          .video {
            width: 100%;
          }
        }
        .info-block {
          z-index: 10;
          font-size: 14px;
          position: absolute;
          color: #999;
          background-color: #080808;
          padding: 2px 6px;
          border-radius: 3px;
          opacity: 0.8;
        }
        .right-top {
          right: 10px;
          top: 8px;
        }
        .left-top {
          left: 10px;
          top: 8px;
        }
      }
      .grid-img {
        height: 176px;
        width: 309px;
      }
      .grid-text {
        display: none;
      }
      .grid-oper {
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 47px;
        align-items: flex-end;
        padding: 0 22px 10px 12px;
        font-size: 14px;
        .name {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        img {
          height: 17px;
          cursor: pointer;
          margin-left: 8px;
        }
        span {
          margin-left: 5px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          position: relative;
          top: -3px;
        }
      }
      &:hover {
        .grid-text {
          display: block;
        }
      }
    }
    .grid-content-first {
      .grid-img {
        height: 364px;
        width: 633px;
      }
      .grid-content {
        height: 364px;
        cursor: pointer;
      }
    }
  }
}
// 媒体查询 大于等于某个宽度
@media screen and (min-width: 1080px) {
  .picture-display {
    .container {
      width: 1060px;
      .grid-content1 {
        .grid-img1 {
          height: 240px;
          width: 343px;
        }
      }
      .grid-content {
        .grid-img {
          height: 142px;
          width: 254px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 142px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 300px;
          width: 523px;
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .picture-display {
    .container {
      width: 1060px;
      .grid-content1 {
        .grid-img1 {
          height: 240px;
          width: 343px;
        }
      }
      .grid-content {
        .grid-img {
          height: 142px;
          width: 254px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 142px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 300px;
          width: 523px;
        }
      }
    }
  }
}
@media screen and (min-width: 1340px) {
  .picture-display {
    .container {
      width: 1280px;
      .grid-content1 {
        .grid-img1 {
          height: 278px;
          width: 415.5px;
        }
      }
      .grid-content {
        .grid-img {
          height: 176px;
          width: 309px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 176px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 364px;
          width: 633px;
        }
      }
    }
  }
}
@media screen and (min-width: 1921px) {
  .picture-display {
    .container {
      width: 1280px;
      .grid-content1 {
        .grid-img1 {
          height: 278px;
          width: 415.5px;
        }
      }
      .grid-content {
        .grid-img {
          height: 176px;
          width: 309px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 176px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 364px;
          width: 633px;
        }
      }
    }
  }
}
</style>
